/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';

// Implement Reddit Pixel
export const onClientEntry = () => {
  // Skip if running on server-side
  if (typeof window === 'undefined') {
    return;
  }

  // Add Reddit Pixel
  const script = document.createElement('script');
  script.innerHTML = `
        !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','a2_g129i3q0annd');rdt('track', 'PageVisit');
      `;
  document.head.appendChild(script);

  // Add Quora Pixel
  const quoraScript = document.createElement('script');
  quoraScript.innerHTML = `
      !function(q,e,v,n,t,s){if(q.qp) return; n=q.qp=function(){n.qp?n.qp.apply(n,arguments):n.queue.push(arguments);}; n.queue=[];t=document.createElement(e);t.async=!0;t.src=v; s=document.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);}(window, 'script', 'https://a.quora.com/qevents.js');
      qp('init', '90a508fb5d2b414797421c5a3cd41257');
      qp('track', 'ViewContent');
    `;
  document.head.appendChild(quoraScript);

  // Add Quora noscript pixel (fallback)
  const quoraNoScript = document.createElement('noscript');
  const quoraPixelImg = document.createElement('img');
  quoraPixelImg.height = '1';
  quoraPixelImg.width = '1';
  quoraPixelImg.style.display = 'none';
  quoraPixelImg.src =
    'https://q.quora.com/_/ad/90a508fb5d2b414797421c5a3cd41257/pixel?tag=ViewContent&noscript=1';
  quoraNoScript.appendChild(quoraPixelImg);
  document.body.appendChild(quoraNoScript);

  // Add Twitter Conversion Tracking
  const twitterScript = document.createElement('script');
  twitterScript.innerHTML = `
    !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
    },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
    a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
    twq('config','orlbe');
  `;
  document.head.appendChild(twitterScript);
};
